@media only screen and (max-width: 600px) {
  .div-img-profile {
    width: 100%;
    position: relative;
    top: -221px;
    z-index: 20;
  }
  .img-profile {
    width: 100%;
  }
}
/* ProjectSection */

.container .card {
  position: relative;
  max-width: 300px;
  height: 180px;
  background-color: #fff;
  margin: 30px 10px;
  padding: 20px 15px;

  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
  border-radius: 15px;
}
.container .card:hover {
  height: 320px;
}

.container .card .image {
  position: relative;
  width: 260px;
  height: 260px;
  top: -40%;
  left: 8px;
  box-shadow: 0 5px 20px #ffbd3900;
  z-index: 1;
}

.container .card .image img {
  max-width: 100%;
  border-radius: 15px;
}

.container .card .content {
  position: relative;
  top: -140px;
  padding: 10px 15px;
  color: #111;
  text-align: center;

  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.container .card:hover .content {
  margin-top: 30px;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.2s;
}
.div-scrollbar {
  overflow-y: scroll;
  height: 150px;
  scrollbar-width: none;
}
/* ProjectSection */

.socialmedia_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px;
}
/* SkillsSection.css */
.skill-icon {
  width: 100px;
  padding-left: 37px;
  font-size: 70px;
}
@media only screen and (max-width: 600px) {
  .skill-icon {
    display: none !important;
  }
  .titleSkill {
    font-size: 16px;
  }
}
.cardskills {
  background: rgb(72, 70, 70);
  width: 25rem;
  border-radius: 20px;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  border: 5px solid transparent;
  transition: transform 0.3s, box-shadow 0.3s, border-color 0.3s;
}

.cardskills:hover {
  border-color: #007bff; /* Change border color to a blue-ish color */
  transform: rotate(3deg); /* Rotate the card by 3 degrees */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Enhanced shadow effect */
}

@keyframes rotate-border {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Linea con titulo */
.title-container {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #ccc; /* Color de la línea */
}

.title {
  margin: 0 20px;
  font-size: 1.5em;
  font-weight: bold;
  color: #333; /* Color del título */
}
/* --- */
.card-efect {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  padding: 1rem; /* Add padding to ensure content is visible */
}

.card-efect img {
  width: 100%;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.card-efect:hover img {
  transform: scale(0.8);
}

.badge {
  display: flex;
  color: white;
  font-size: 10px;
  font-weight: bold;
  margin: 0 0.2rem;
  transition: background 0.3s ease, transform 0.3s ease;
}

.badge:hover {
  background: rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
}

.badge.red {
  background: red;
}

.badge.blue {
  background: blue;
}

.card-body {
  padding: 1rem;
  text-align: center;
  transition: opacity 0.3s ease;
}

/* Animation for the section */
#skills-section {
  animation: fadeInUp 1s ease forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
